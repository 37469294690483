import Vue from "vue";
import Vuex from "vuex";
import authentication from "./modules/authentication";
import user from "./modules/user";
import application from "./modules/application";
import company from "./modules/company";
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    authentication,
    user,
    application,
    company,
  },
});

export default store;
