<script>
import loginForm from "@/components/login/loginForm";
export default {
  name: "login",
  components: {
    loginForm,
  },
};
</script>

<template>
  <login-form></login-form>
</template>
