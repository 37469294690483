import { postRequest, getRequest, deleteRequest } from "../../../requests/requests";

const state = () => ({
  allCompanies: [],
  allCompaniesDropdown: [],
  selectedCompany: null,
  companyTableStatus: null,
  serverItemsLength: null,
  filters: {
    itemsPerPage: 10,
    page: 1,
    textFilter: null,
  },
  companyTypes: [],
});

const getters = {
  allCompanies: (state) => state.allCompanies,
  selectedCompany: (state) => state.selectedCompany,
  filters: (state) => state.filters,
  companyTableStatus: (state) => state.companyTableStatus,
  serverItemsLength: (state) => state.serverItemsLength,
  allCompaniesDropdown: (state) => state.allCompaniesDropdown,
  companyTypes: (state) => state.companyTypes,
};

const actions = {
  async getCompanies({ commit, getters }) {
    commit("setCompanyTableStatus", "loading");
    var result = await getRequest("company", "get", getters.filters);
    commit("setCompanyTableStatus", null);
    commit("setAllCompanies", result);
  },
  async updateCompany({ dispatch, commit, getters }) {
    await postRequest("company", "createOrUpdate", getters.selectedCompany);
    commit("setSelectedCompany", null);
    await dispatch("getCompanies");
  },
  async setSelectedCompany({ commit }, data) {
    commit("setSelectedCompany", data);
  },
  async deleteCompany({ commit, getters }) {
    await deleteRequest("company", "delete", getters.selectedCompany.id);
    commit("getCompanies", null);
  },
  async getCompaniesDropdownSource({ commit }) {
    var result = await getRequest("company", "get", null);
    commit("setAllCompaniesDropdownSource", result);
  },
  async getCompanyTypes({ commit }) {
    var result = await getRequest("lookup", "companyTypes", null);
    commit("setCompanyTypes", result.data);
  },
};

const mutations = {
  setAllCompanies(state, val) {
    state.allCompanies = val.data.items;
    state.serverItemsLength = val.data.count;
  },
  setSelectedCompany(state, val) {
    state.selectedCompany = val;
  },
  setFilters(state, val) {
    state.filters = val;
  },
  setCompanyTableStatus(state, val) {
    state.companyTableStatus = val;
  },
  setAllCompaniesDropdownSource(state, val) {
    state.allCompaniesDropdown = val.data.items;
  },
  setCompanyTypes(state, val) {
    state.companyTypes = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
