<script>
import dataTable from "../../common/dataTable.vue";
import userObject from "./userObject";

export default {
  components: { dataTable },
  name: "user-table",
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Role", value: "role", sortable: false },
        {
          text: "Associated Companies",
          value: "companyDisplay",
          sortable: false,
          options: { type: "chip-list", chipKey: "company.id", chipValue: "company.name" },
        },
        { text: "Application Access", value: "applicationDisplay", sortable: false },
        { text: "Last Login", value: "lastLogin", sortable: false },
      ],
      userObject: userObject,
      dialogVisible: false,
    };
  },
  methods: {
    async get() {
      await this.$store.dispatch("user/getUsers");
    },
    edit(item) {
      this.$store.commit("user/setSelectedUser", item);
      this.dialogVisible = true;
    },
    add() {
      this.$store.commit("user/setSelectedUser", JSON.parse(JSON.stringify(this.userObject)));
      this.dialogVisible = true;
    },
    async update() {
      await this.$store.dispatch("user/updateUser");
      this.dialogVisible = false;
    },
    async deleteItem() {
      await this.$store.dispatch("user/deleteUser");
      this.dialogVisible = false;
    },
    close() {
      this.dialogVisible = false;
      this.$store.commit("user/setSelectedUser", null);
    },
  },
  mounted() {},
  computed: {
    filters: {
      get() {
        return this.$store.getters["user/filters"];
      },
      set(val) {
        this.$store.commit("user/setFilters", val);
      },
    },
    allUsers() {
      return this.$store.getters["user/allUsers"];
    },
    serverItemsLength() {
      return this.$store.getters["user/serverItemsLength"];
    },
    selectedUser() {
      return this.$store.getters["user/selectedUser"];
    },
    allApplications() {
      return this.$store.getters["application/allApplications"];
    },
    allRoles() {
      return this.$store.getters["user/allRoles"];
    },
    allCompanies() {
      var allCompanies = this.$store.getters["company/allCompaniesDropdown"];
      var result = [];
      for (var i = 0; i < allCompanies.length; i++) {
        if (result.filter((x) => x.name == allCompanies[i].name).length) {
          continue;
        }
        result.push(allCompanies[i]);
      }
      return result;
    },
    userTableStatus() {
      return this.$store.getters["user/userTableStatus"];
    },
  },
  watch: {
    filters() {
      this.$store.dispatch("user/getUsers");
    },
  },
};
</script>
<template>
  <data-table
    title="Users"
    object-name="User"
    :headers="headers"
    :items="allUsers"
    editable
    :defaultObject="userObject"
    :active-item="selectedUser"
    :server-items-length="serverItemsLength"
    :filters.sync="filters"
    :dialog.sync="dialogVisible"
    :loading="userTableStatus"
    @get="get"
    @add="add"
    @edit="edit"
    @update="update"
    @delete="deleteItem"
    @close="close"
  >
    <template v-slot:editForm="{ item }">
      <v-text-field class="mb-2" outlined hide-details="auto" v-model="item.name" dense label="Name"></v-text-field>
      <v-text-field class="mb-2" outlined hide-details="auto" v-model="item.email" dense label="Email Address"></v-text-field>
      <v-select
        class="mb-2"
        outlined
        hide-details="auto"
        v-model="item.role"
        dense
        label="Role"
        :items="allRoles"
        item-text="value"
        item-value="key"
      ></v-select>
      <v-autocomplete
        class="mb-2"
        outlined
        hide-details="auto"
        v-model="item.companies"
        dense
        label="Companies"
        :items="allCompanies"
        item-text="name"
        item-value="id"
        multiple
      ></v-autocomplete>
      <v-autocomplete
        class="mb-2"
        outlined
        hide-details="auto"
        v-model="item.applications"
        dense
        label="Applications"
        :items="allApplications"
        item-text="name"
        item-value="id"
        multiple
      ></v-autocomplete>
    </template>
  </data-table>
</template>
