var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"login-bg fill-height ma-0 pa-0",staticStyle:{"position":"relative"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"sm":"8","md":"6","lg":"4"}},[_c('Particles',{attrs:{"id":"tsparticles","particlesInit":_vm.particlesInit,"particlesLoaded":_vm.particlesLoaded,"options":{
        background: {
          color: {
            value: '#0d47a1',
          },
        },
        fpsLimit: 120,
        particles: {
          color: {
            value: '#ffffff',
          },
          links: {
            color: '#ffffff',
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: false,
          },
          move: {
            direction: 'none',
            enable: true,
            outModes: {
              default: 'bounce',
            },
            random: false,
            speed: 3,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 80,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: 'circle',
          },
          size: {
            value: { min: 1, max: 5 },
          },
        },
        detectRetina: true,
      }}}),_c('v-card',{staticClass:"pa-5 mx-auto",attrs:{"max-width":"400px"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"contain":"","max-width":"250px","src":require('@/assets/images/app-logos/seahub-logo.png')}})],1)],1),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.attemptLogin.apply(null, arguments)}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Email Address","rules":[_vm.required, _vm.validEmail],"hide-details":"auto"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"label":"Password","placeholder":' ',"rules":[_vm.required],"outlined":"","hide-details":"auto"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"loading":_vm.isLoggingIn,"width":"100%","color":"primary","type":"submit"}},[_vm._v("Login")])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('a',{staticClass:"d-inline-block info--text",on:{"click":function($event){_vm.showResetPassword = true}}},[_vm._v("Forgotten password? Click here to reset")])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('corporate-logo')],1)],1)],1)],1),_c('reset-password-modal',{attrs:{"visible":_vm.showResetPassword},on:{"close":function($event){_vm.showResetPassword = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }