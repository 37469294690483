<script>
import applicationTile from "../components/applicationTile.vue";
export default {
  components: { applicationTile },
  name: "dashboard",
  data() {
    return {
      applicationData: [
        {
          name: "Seabay",
          description:
            "Seabroker's internal system for managing all requirements, offers, fixtures and related data.",
          logo: "seabay-logo.png",
          devLink: "https://localhost:44338/sso/login",
          prodLink: "https://theseabay.com/sso/Login",
          internal: true,
        },
        {
          name: "Seaprojects",
          description:
            "Seabroker's internal system for managing 'project' requirements and associated offers",
          logo: "seaprojects-logo.png",
          devLink: "http://localhost:8090/sso",
          prodLink: "https://seaprojects.theseabay.com/sso",
          internal: true,
        },
        {
          name: "Seagoals",
          description:
            "Seabroker's internal system for managing company wide objectives and achievements",
          logo: "seagoal-logo.png",
          devLink: "http://localhost:8081/sso",
          prodLink: "https://pointmaker.theseabay.com/sso",
          internal: true,
        },
        {
          name: "Seaportal",
          description:
            "Manage all your requirements, offers and fixtures as well as any associated documentation all in one place.",
          logo: "seaportal-logo.png",
          devLink: "http://localhost:8081/sso",
          prodLink: "https://portal.theseabay.com/sso",
          internal: false,
        },
        {
          name: "Sealog",
          description:
            "Voyage logging software with extensive reporting and analysis capabilities",
          logo: "sealog-logo.png",
          devLink: "http://localhost:8083/sso",
          prodLink: "https://sealog.co.uk/sso",
          internal: false,
        },
        {
          name: "Seabid",
          description: "Host Real-time reverse auctions",
          logo: "seabid-logo.png",
          devLink: "https://stage.seabid.theseabay.com/sso",
          prodLink: "https://seabid.theseabay.com/sso",
          internal: false,
        },
        {
          name: "Seaspotter",
          description: "Bespoke, customizable vessel tracking software",
          logo: "seaspotter-logo.png",
          devLink: "https://seaspotter.theseabay.com/sso",
          prodLink: "https://seaspotter.theseabay.com/sso",
          internal: false,
        },
        {
          name: "Searouter",
          description: "Estimate your voyage consumption",
          logo: "searouter-logo.png",
          devLink: null,
          prodLink: null,
          internal: false,
        },
      ],
    };
  },
  methods: {
    logIn(domain) {
      window.open(`${domain}?token=${this.token}`);
    },
    onUnfinishedClick() {
      window.alert("Coming soon!");
    },
  },
  computed: {
    isProduction() {
      return process.env.VUE_APP_ENVIRONMENT == "Production";
    },
    token() {
      return this.$store.getters.token;
    },
    userName() {
      return this.$store.getters.userName;
    },
    redirectUrl() {
      return this.$store.getters.redirectUrl;
    },
    isInternalUser() {
      return this.$store.getters.isInternalUser;
    },
    includedApplications() {
      if (this.isInternalUser) {
        return this.applicationData;
      }
      return this.applicationData.filter((x) => !x.internal);
    },
    hubAdministrationAccess() {
      return this.$store.getters.hubAdministrationAccess;
    },
  },
  mounted() {
    //detect whether sign in has come from a redirect
    if (this.redirectUrl) {
      window.location.href = `${this.redirectUrl}?token=${this.token}`;
    }
  },
};
</script>

<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col>
        <v-img
          contain
          class="mx-auto"
          max-width="250px"
          :src="require('@/assets/images/app-logos/seahub-logo.png')"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="text-align: center">
        <h2 style="color: #5a5a5a">Welcome to Seahub, {{ userName }}!</h2>
        <i>Click on a tile below to open the application in a new tab.</i>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <application-tile
        v-for="(item, index) in includedApplications"
        :key="`${index}}`"
        @click="
          item.name == 'Searouter'
            ? onUnfinishedClick()
            : logIn(isProduction ? item.prodLink : item.devLink)
        "
        :description="item.description"
        :internal="item.internal"
        >{{ item.name
        }}<template v-slot:logo>
          <v-img
            contain
            class="mx-auto"
            max-height="100px"
            :src="require(`@/assets/images/app-logos/${item.logo}`)"
          ></v-img></template
      ></application-tile>
    </v-row>
    <v-row>
      <v-col class="text-right">
        <v-spacer></v-spacer>
        <v-btn
          v-if="hubAdministrationAccess"
          class="mr-2"
          text-only
          small
          @click="$router.push({ path: '/administration' })"
          >HUB MANAGEMENT<v-icon class="ml-2">mdi-tune</v-icon></v-btn
        >
        <v-btn text-only small @click="$store.dispatch('logout')"
          >SIGNOUT<v-icon class="ml-2">mdi-logout</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
