import axios from "axios";

export async function getRequest(controller, method, data) {
  const url = `${controller || ""}/${method}`;

  return axios
    .get(url, {
      params: data,
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function postRequest(controller, method, data) {
  const url = `${controller || ""}/${method}`;

  return axios.post(url, data).catch((error) => {
    alert(error.response.data);
    return null;
  });
}

export async function deleteRequest(controller, method, data) {
  const url = `${controller || ""}/${method}`;

  return axios.delete(url, { data: data }).catch((error) => {
    alert(error.response.data);
    return null;
  });
}
