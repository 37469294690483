<script>
import dataTable from "../../common/dataTable.vue";
import companyObject from "./companyObject";

export default {
  components: { dataTable },
  name: "company-table",
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Type", value: "companyType", sortable: false },
      ],
      companyObject: companyObject,
      dialogVisible: false,
    };
  },
  methods: {
    async get() {
      await this.$store.dispatch("company/getCompanies");
    },
    edit(item) {
      this.$store.commit("company/setSelectedCompany", item);
      this.dialogVisible = true;
    },
    add() {
      this.$store.commit("company/setSelectedCompany", JSON.parse(JSON.stringify(this.companyObject)));
      this.dialogVisible = true;
    },
    async update() {
      await this.$store.dispatch("company/updateCompany");
      this.dialogVisible = false;
    },
    async deleteItem() {
      await this.$store.dispatch("company/delete");
      this.dialogVisible = false;
    },
    close() {
      this.dialogVisible = false;
      this.$store.commit("company/setSelectedCompany", null);
    },
  },
  mounted() {},
  computed: {
    filters: {
      get() {
        return this.$store.getters["company/filters"];
      },
      set(val) {
        this.$store.commit("company/setFilters", val);
      },
    },
    allCompanies() {
      return this.$store.getters["company/allCompanies"];
    },
    allApplications() {
      return this.$store.getters["application/allApplicationsDropdown"];
    },
    serverItemsLength() {
      return this.$store.getters["company/serverItemsLength"];
    },
    selectedCompany() {
      return this.$store.getters["company/selectedCompany"];
    },
    allCompanyTypes() {
      return this.$store.getters["company/companyTypes"];
    },
    companyTableStatus() {
      return this.$store.getters["company/companyTableStatus"];
    },
  },
  watch: {
    filters() {
      this.$store.dispatch("company/getCompanies");
    },
  },
};
</script>
<template>
  <data-table
    title="Companies"
    object-name="Company"
    :headers="headers"
    :items="allCompanies"
    editable
    :defaultObject="companyObject"
    :active-item="selectedCompany"
    :server-items-length="serverItemsLength"
    :filters.sync="filters"
    :dialog.sync="dialogVisible"
    :loading="companyTableStatus"
    @get="get"
    @add="add"
    @edit="edit"
    @update="update"
    @delete="deleteItem"
    @close="close"
  >
    <template v-slot:editForm="{ item }">
      <v-text-field class="mb-2" outlined hide-details="auto" v-model="item.name" dense label="Name"></v-text-field>
      <v-textarea class="mb-2" outlined hide-details="auto" v-model="item.description" dense label="Description"></v-textarea>
      <v-select
        class="mb-2"
        outlined
        hide-details="auto"
        v-model="item.companyType"
        dense
        label="Type"
        :items="allCompanyTypes"
        item-text="value"
        item-value="key"
      ></v-select>
    </template>
  </data-table>
</template>
