import Vue from "vue";
import App from "./App.vue";
import vuetify from "@/vuetify/vuetify"; // path to vuetify export
import axios from "axios";
import VueAxios from "vue-axios";
import router from "@/plugins/router/router";
import store from '@/plugins/store'
import Particles from "particles.vue";

Vue.use(Particles);
Vue.config.productionTip = false;

axios.defaults.withCredentials = true;
axios.defaults.headers.get["Cache-Control"] = "no-cache, no-store";
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.use(VueAxios, axios);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
