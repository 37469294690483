<script>
import * as debounce from "lodash.debounce";

export default {
  name: "data-table",
  props: {
    title: { type: String, default: null },
    objectName: { type: String, default: null },
    headers: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    editable: { type: Boolean, default: false },
    defaultObject: { type: Object, default: null },
    activeItem: { type: Object, default: null },
    serverItemsLength: { type: Number, default: null },
    filters: { type: Object, default: null },
    dialog: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    footerProps: {
      type: Object,
      default: () => {
        return { "items-per-page-options": [10, 20, 30, 40, 50, 100] };
      },
    },
  },
  data() {
    return {
      searchInput: null,
    };
  },
  methods: {
    addItem() {
      this.$emit("add");
    },
    getItems() {
      this.$emit("get");
    },
    editItem(val) {
      this.$emit("edit", val);
    },
    updateItem() {
      this.$emit("update");
    },
    deleteItem() {
      this.$emit("delete");
    },
    debounceSearch: debounce(function () {
      this.localOptions.textFilter = this.searchInput;
    }, 500),
    close() {
      this.$emit("close");
    },
  },
  computed: {
    isEdit() {
      return this.activeItem && this.activeItem.id ? true : false;
    },
    localDialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("update:dialog", val);
      },
    },
    localOptions: {
      get() {
        return this.filters;
      },
      set(val) {
        this.$emit("update:filters", val);
      },
    },
  },
  watch: {
    filters: {
      handler() {
        this.$nextTick(() => this.getItems());
      },
      deep: true,
    },
  },
};
</script>
<template>
  <v-card elevation="2" class="ma-3">
    <v-card-title>
      <span class="mr-2 text-h6"
        >{{ title }}
        <v-btn title="Click to Add" v-if="editable" icon color="primary" dark class="" @click="addItem"><v-icon>mdi-plus</v-icon> </v-btn></span
      >
    </v-card-title>
    <v-card-text>
      <v-data-table
        dense
        @click:row="editItem"
        :items="items"
        :headers="headers"
        :server-items-length="serverItemsLength"
        :options.sync="localOptions"
        :footer-props="footerProps"
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat style="padding-left: 0px; width: 100%">
            <v-row>
              <v-col>
                <v-text-field
                  hide-details="auto"
                  dense
                  style="float: right; max-width: 200px"
                  class="float-right"
                  v-model="searchInput"
                  v-on:input="debounceSearch"
                  append-icon="fas fa-search"
                  :label="`Filter ${objectName}s...`"
                  clearable
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="localDialog" width="80%">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ `${isEdit ? "Edit" : "Add"} ${objectName}` }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation v-if="activeItem">
            <slot name="editForm" :item="activeItem"></slot>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="close">Cancel</v-btn>
          <v-btn color="error" @click="deleteItem">Delete</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="updateItem">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
