import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import customTheme from "./customTheme";
Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: customTheme,
    },
  },
};

export default new Vuetify(opts);
