import { postRequest, getRequest, deleteRequest } from "../../../requests/requests";

const state = () => ({
  allUsers: [],
  selectedUser: null,
  userTableStatus: null,
  filters: {
    itemsPerPage: 10,
    page: 1,
    textFilter: null,
  },
  serverItemsLength: null,
  allRoles: [],
});

const getters = {
  allUsers: (state) => state.allUsers,
  selectedUser: (state) => state.selectedUser,
  userTableStatus: (state) => state.userTableStatus,
  filters: (state) => state.filters,
  serverItemsLength: (state) => state.serverItemsLength,
  allRoles: (state) => state.allRoles,
};

const actions = {
  async getUsers({ commit, getters }) {
    commit("setUserTableStatus", "loading");
    var result = await getRequest("user", "get", getters.filters);
    commit("setUserTableStatus", null);
    commit("setAllUsers", result);
  },
  async updateUser({ dispatch, commit, getters }) {
    await postRequest("user", "createOrUpdate", getters.selectedUser);
    commit("setSelectedUser", null);
    await dispatch("getUsers");
  },
  async setSelectedUser({ commit }, data) {
    commit("setSelectedUser", data);
  },
  async deleteUser({ commit, getters, dispatch }) {
    await deleteRequest("user", `delete/${getters.selectedUser.id}`);
    commit("setSelectedUser", null);
    await dispatch("getUsers");
  },
  async getRoles({ commit }) {
    var result = await getRequest("lookup", "roles");
    commit("setUserRoles", result.data);
  },
};

const mutations = {
  setAllUsers(state, val) {
    state.allUsers = val.data.items;
    state.serverItemsLength = val.data.count;
  },
  setSelectedUser(state, val) {
    state.selectedUser = val;
  },
  setFilters(state, val) {
    state.filters = val;
  },
  setUserTableStatus(state, val) {
    state.userTableStatus = val;
  },
  setUserRoles(state, val) {
    state.allRoles = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
