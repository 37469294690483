function validateEmail(v) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(v);
}

export default {
  computed: {
    required() {
      return (v) => !!v || "Required";
    },
    validEmail() {
      return (v) => validateEmail(v) || "Invalid Email Address";
    },
  },
};
