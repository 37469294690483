export default {
  id: null,
  name: null,
  companies: [],
  companiesDisplay: "",
  role: null,
  lastLogin: null,
  applications: [],
  applicationsDisplay: "",
};
