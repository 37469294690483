<script>
export default {
  name: "corporate-logo",
};
</script>

<template>
  <div
    class="group-logo-wrapper"
    title="Courtesy of Seabrokers - The shipbroker with a difference"
    @click="window.open(`https://www.seabrokers.co.uk/shipping/`, `_blank`)"
  >
    <img
      style="max-width: 200px"
      :src="require('@/assets/images/seabrokers-group-logo- small.png')"
    />
  </div>
</template>
