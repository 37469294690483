import Vue from "vue";
import Router from "vue-router";
import Administration from "../../views/Administration.vue";
import Login from "../../views/Login.vue";
import Dashboard from "../../views/Dashboard.vue";
import store from "../store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/dashboard",
      alias: "/",
      name: "dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true,
        title: "Dashboard",
      },
    },
    {
      path: "/administration",
      name: "administration",
      component: Administration,
      meta: {
        requiresAuth: true,
        title: "Account",
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        requiresAuth: false,
        title: "Login",
      },
    },
    {
      path: "*",
      redirect: "/login",
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  var authenticated = store.getters.isAuthenticated == true ? true : await store.dispatch("authenticate");

  var redirectUrl = to.query.redirect;

  if (redirectUrl && authenticated) {
    window.location.href = `${redirectUrl}?token=${store.getters.token}`;
  } else if (redirectUrl) {
    store.commit("setRedirectUrl", redirectUrl);
  }

  if (to.logout) {
    store.dispatch("logout");
    next({ name: "login" });
  }

  if (!authenticated) {
    if (to.name == "login") {
      next();
    } else {
      next({ name: "login" });
    }
  } else {
    if (to.name == "login") {
      next({ name: "dashboard" });
    } else {
      next();
    }
  }
});

export default router;
