<script>
import userTable from "./../components/administration/users/userTable.vue";
import companyTable from "./../components/administration/company/companyTable.vue";
import applicationTable from "./../components/administration/application/applicationTable.vue";

export default {
  name: "administration",
  components: { userTable, companyTable, applicationTable },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    totalUsers() {
      return this.$store.getters["user/serverItemsLength"];
    },
    totalCompanies() {
      return this.$store.getters["company/serverItemsLength"];
    },
    totalApplications() {
      return this.$store.getters["application/serverItemsLength"];
    },
  },
  mounted() {
    this.$store.dispatch("user/getRoles");
    this.$store.dispatch("company/getCompaniesDropdownSource");
    this.$store.dispatch("application/getApplicationsDropdown");
    this.$store.dispatch("company/getCompanyTypes");
  },
};
</script>

<template>
  <v-container fluid>
    <v-tabs v-model="tab">
      <v-tab><v-icon class="mr-1">mdi-account-group</v-icon> Users ({{ totalUsers ? totalUsers : 0 }})</v-tab>
      <v-tab><v-icon class="mr-1">mdi-domain</v-icon>Companies ({{ totalCompanies ? totalCompanies : 0 }})</v-tab>
      <v-tab><v-icon class="mr-1">mdi-apps</v-icon> Applications ({{ totalApplications ? totalApplications : 0 }})</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" ea>
      <v-tab-item eager>
        <user-table></user-table>
      </v-tab-item>
      <v-tab-item eager>
        <company-table></company-table>
      </v-tab-item>
      <v-tab-item eager>
        <application-table></application-table>
      </v-tab-item>
    </v-tabs-items>
    <v-row>
      <v-col class="text-right">
        <v-spacer></v-spacer>
        <v-btn class="mr-2" text-only small @click="$router.push({ path: '/dashboard' })"
          >DASHBOARD<v-icon class="ml-2">mdi-monitor-dashboard</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
