<template>
  <v-col cols="12" md="6">
    <div style="position: relative; height: 100%">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-img
            contain
            class="internal-icon"
            width="28px"
            :src="require('@/assets/images/seabrokersRoundIcon.png')"
            v-if="internal"
            placeholder="eqweqwe"
            v-on="on"
          />
        </template>
        <span>Internal Users Only</span>
      </v-tooltip>
      <v-card class="rounded-xl" @click="$emit('click')" hover style="height: 100%; position: relative; text-align: center">
        <slot name="logo"></slot>
        <i>{{ description }}</i>
      </v-card>
    </div>
  </v-col>
</template>

<script>
export default {
  name: "application-tile",
  props: {
    link: String,
    internal: Boolean,
    description: String,
  },
  data() {
    return {
      reveal: false,
    };
  },
};
</script>

<style scoped>
.internal-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  display: inline;
  z-index: 2;
}
</style>
