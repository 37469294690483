<script>
import dataTable from "../../common/dataTable.vue";
import applicationObject from "./applicationObject";

export default {
  components: { dataTable },
  name: "application-table",
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Internal", value: "isInternal", sortable: false },
        { text: "No. of Users", value: "userCount", sortable: false },
      ],
      applicationObject: applicationObject,
      dialogVisible: false,
    };
  },
  methods: {
    async get() {
      await this.$store.dispatch("application/getApplications");
    },
    edit(item) {
      this.$store.commit("application/setSelectedApplication", item);
      this.dialogVisible = true;
    },
    add() {
      this.$store.commit("application/setSelectedApplication", JSON.parse(JSON.stringify(this.applicationObject)));
      this.dialogVisible = true;
    },
    async update() {
      await this.$store.dispatch("application/updateApplication");
      this.dialogVisible = false;
    },
    async deleteItem() {
      await this.$store.dispatch("application/delete");
      this.dialogVisible = false;
    },
    close() {
      this.dialogVisible = false;
      this.$store.commit("application/setSelectedApplication", null);
    },
  },
  mounted() {},
  computed: {
    filters: {
      get() {
        return this.$store.getters["application/filters"];
      },
      set(val) {
        this.$store.commit("application/setFilters", val);
      },
    },
    allApplications() {
      return this.$store.getters["application/allApplications"];
    },
    serverItemsLength() {
      return this.$store.getters["application/serverItemsLength"];
    },
    selectedApplication() {
      return this.$store.getters["application/selectedApplication"];
    },
    applicationTableStatus() {
      return this.$store.getters["application/applicationTableStatus"];
    },
  },
  watch: {
    filters() {
      this.$store.dispatch("application/getApplications");
    },
  },
};
</script>
<template>
  <data-table
    title="Applications"
    object-name="Application"
    :headers="headers"
    :items="allApplications"
    editable
    :defaultObject="applicationObject"
    :active-item="selectedApplication"
    :server-items-length="serverItemsLength"
    :filters.sync="filters"
    :dialog.sync="dialogVisible"
    :loading="applicationTableStatus"
    @get="get"
    @add="add"
    @edit="edit"
    @update="update"
    @delete="deleteItem"
    @close="close"
  >
    <template v-slot:editForm="{ item }">
      <v-text-field class="mb-2" outlined hide-details="auto" v-model="item.name" dense label="Name"></v-text-field>
      <v-textarea class="mb-2" outlined hide-details="auto" v-model="item.description" dense label="Description"></v-textarea>
      <v-text-field class="mb-2" outlined hide-details="auto" v-model="item.key" dense label="Key"></v-text-field>
      <v-text-field class="mb-2" outlined hide-details="auto" v-model="item.url" dense label="Application Url"></v-text-field>
      <v-checkbox class="mb-2" outlined hide-details="auto" v-model="item.isInternal" dense label="Internal Use Only"></v-checkbox>
    </template>
  </data-table>
</template>
