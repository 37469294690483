<template>
  <v-dialog v-model="modal" width="40%" @click:outside="close">
    <v-card>
      <v-card-title>
        <span class="headline primary--text">Reset Password</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <i>If there is an account associated with the email address provided below, you will recieve a password reset link</i>
          <v-row>
            <v-col>
              <v-text-field :placeholder="' '" v-model="email" label="Email Address" :rules="[required, validEmail]"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="warning" @click="close()">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="resetPassword()">Send Reset Link</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "../../mixins/rules";

export default {
  name: "reset-password-modal",
  mixins: [rules],
  components: {},
  props: {
    visible: Boolean,
  },
  data: () => {
    return {
      email: "",
      modal: false,
    };
  },
  methods: {
    isValid() {
      return this.$refs.form.validate();
    },
    resetPassword() {
      if (!this.isValid()) return;

      this.close();

      this.get("login", `Reset`, { email: this.email }).then((result) => {
        if (result) {
          this.$store.commit("setMessage", {
            title: "Password Reset",
            type: "info",
            content: `Please check your emails for a reset link`,
          });
        } else {
          this.$store.commit("setMessage", {
            title: "Unable to reset password",
            type: "error",
            content: `We were unable to reset your password - Please contact an administrator.`,
          });
        }
      });
    },
    keyDownHandler(e) {
      if (e.keyCode == 13) {
        e.preventDefault();
        this.resetPassword();
      }
    },
    close() {
      this.$emit("close");
    },
  },
  created() {
    window.addEventListener("keydown", this.keyDownHandler);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
  watch: {
    visible(val) {
      this.modal = val;
    },
  },
};
</script>
