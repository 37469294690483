import { postRequest, getRequest } from "../../../requests/requests";
import route from "../../router/router";

const state = () => ({
  userId: null,
  userName: null,
  userEmailAddress: null,
  userCompanies: null,
  userApplications: [],
  userStatus: null,
  navVisible: false,
  userRole: null,
  token: null,
  redirectUrl: null,
  isInternalUser: false,
});

const getters = {
  userStatus: (state) => state.userStatus,
  isAuthenticated: (state) => state.userStatus === "logged-in",
  userRole: (state) => state.userRole,
  userEmail: (state) => state.userEmailAddress,
  userName: (state) => state.userName,
  token: (state) => state.token,
  redirectUrl: (state) => state.redirectUrl,
  isInternalUser: (state) => state.isInternalUser,
  hubAdministrationAccess: (state) =>
    state.isInternalUser && state.userRole == "super administrator",
};

const actions = {
  async login({ commit }, params) {
    var result = await postRequest("authentication", "signin", params);

    if (result) {
      commit("setUserStatus", "logged-in");
      commit("setUserData", result.data.userData);
      commit("setToken", result.data.token);
      route.push({ name: "dashboard" });
    } else {
      commit("setUserStatus", null);
    }
  },
  async logout({ commit }) {
    await getRequest("authentication", "signout");

    commit("setUserStatus", null);
    route.push({ name: "login" });
  },
  async authenticate({ commit }) {
    var result = await getRequest("authentication", "authenticate");
    if (!result) {
      return;
    }
    commit("setUserStatus", result.data ? "logged-in" : null);
    if (result.data) {
      commit("setUserStatus", result.data.userData == null ? null : "logged-in");
      commit("setUserData", result.data.userData);
      commit("setToken", result.data.token);
      if (result.data.userData != null) return true;
    }
    return false;
  },
};

const mutations = {
  setUserStatus(state, val) {
    state.userStatus = val;
  },
  setUserData(state, val) {
    state.userId = val ? val.id : null;
    state.userRole = val ? val.role : null;
    state.userName = val ? val.name : null;
    state.userEmailAddress = val ? val.email : null;
    state.userCompanies = val ? (val.companyList ? val.companyList : []) : [];
    state.userApplications = val ? (val.applicationList ? val.applicationList : []) : [];
    state.isInternalUser = val ? val.isInternalUser : false;
  },
  setToken(state, val) {
    state.token = val;
  },
  setRedirectUrl(state, val) {
    state.redirectUrl = val;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
