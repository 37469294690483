<template>
  <v-row
    style="position: relative"
    class="login-bg fill-height ma-0 pa-0"
    align="center"
    justify="center"
  >
    <v-col sm="8" md="6" lg="4">
      <Particles
        id="tsparticles"
        :particlesInit="particlesInit"
        :particlesLoaded="particlesLoaded"
        :options="{
          background: {
            color: {
              value: '#0d47a1',
            },
          },
          fpsLimit: 120,
          particles: {
            color: {
              value: '#ffffff',
            },
            links: {
              color: '#ffffff',
              distance: 150,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: false,
            },
            move: {
              direction: 'none',
              enable: true,
              outModes: {
                default: 'bounce',
              },
              random: false,
              speed: 3,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: 'circle',
            },
            size: {
              value: { min: 1, max: 5 },
            },
          },
          detectRetina: true,
        }"
      />
      <v-card class="pa-5 mx-auto" max-width="400px">
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-img
              contain
              class="mx-auto"
              max-width="250px"
              :src="require('@/assets/images/app-logos/seahub-logo.png')"
            ></v-img>
          </v-col>
        </v-row>

        <v-form ref="form" @submit.prevent="attemptLogin">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                dense
                outlined
                v-model="email"
                label="Email Address"
                :rules="[required, validEmail]"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                :placeholder="' '"
                @click:append="showPassword = !showPassword"
                :rules="[required]"
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn
                :loading="isLoggingIn"
                width="100%"
                color="primary"
                type="submit"
                >Login</v-btn
              >
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <a
                @click="showResetPassword = true"
                class="d-inline-block info--text"
                >Forgotten password? Click here to reset</a
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0"> <corporate-logo> </corporate-logo> </v-col>
          </v-row>
        </v-form>
      </v-card>

      <reset-password-modal
        :visible="showResetPassword"
        @close="showResetPassword = false"
      ></reset-password-modal>
    </v-col>
  </v-row>
</template>
<script>
import rules from "../../mixins/rules";
import resetPasswordModal from "../login/resetPasswordModal.vue";
import corporateLogo from "../layout/seabrokers/corporateLogo";
import { loadFull } from "tsparticles";

export default {
  mixins: [rules],
  components: {
    resetPasswordModal,
    corporateLogo,
  },
  data: () => {
    return {
      email: "",
      password: "",
      showPassword: false,
      rememberMe: true,
      showResetPassword: false,
    };
  },
  methods: {
    attemptLogin() {
      this.userStatus = "logging-in";
      var email = this.email;
      var password = this.password;
      if (this.$refs.form.validate()) {
        this.$store.dispatch("login", { email, password });
      }
    },
    keyDownHandler(e) {
      if (e.keyCode == 13) {
        e.preventDefault();
        this.attemptLogin();
      }
    },
    loadFull: loadFull,
    async particlesInit(engine) {
      await loadFull(engine);
    },
    async particlesLoaded(container) {
      console.log("Particles container loaded", container);
    },
  },
  computed: {
    isLoggingIn() {
      return this.userStatus == "logging-in";
    },
    userStatus: {
      get() {
        return this.$store.getters.userStatus;
      },
      set(val) {
        this.$store.commit("setUserStatus", val);
      },
    },
  },
  created() {
    window.addEventListener("keydown", this.keyDownHandler);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDownHandler);
  },
};
</script>

<style>
.login-bg {
  background-image: url("../../assets/images/tiled-bglogo.png");
  background-repeat: repeat;
}
</style>
