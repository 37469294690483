import { postRequest, getRequest, deleteRequest } from "../../../requests/requests";

const state = () => ({
  allApplications: [],
  allApplicationsDropdown: [],
  selectedApplication: null,
  applicationTableStatus: null,
  filters: {
    itemsPerPage: 10,
    page: 1,
    textFilter: null,
  },
  serverItemsLength: null,
  allRoles: [],
});

const getters = {
  allApplications: (state) => state.allApplications,
  allApplicationsDropdown: (state) => state.allApplicationsDropdown,
  selectedApplication: (state) => state.selectedApplication,
  applicationTableStatus: (state) => state.applicationTableStatus,
  filters: (state) => state.filters,
  serverItemsLength: (state) => state.serverItemsLength,
  allRoles: (state) => state.allRoles,
};

const actions = {
  async getApplications({ commit, getters }) {
    commit("setApplicationTableStatus", "loading");
    var result = await getRequest("application", "get", getters.filters);
    commit("setApplicationTableStatus", null);
    commit("setAllApplications", result);
  },
  async updateApplication({ dispatch, commit, getters }) {
    await postRequest("application", "createOrUpdate", getters.selectedApplication);
    commit("setSelectedApplication", null);
    await dispatch("getApplications");
  },
  async setSelectedApplication({ commit }, data) {
    commit("setSelectedApplication", data);
  },
  async deleteApplication({ commit, getters }) {
    await deleteRequest("application", "delete", getters.selectedApplication.id);
    commit("setSelectedApplication", null);
  },
  async getApplicationsDropdown({ commit }) {
    var result = await getRequest("application", "get", null);
    commit("setAllApplicationsDropdown", result);
  },
};

const mutations = {
  setAllApplications(state, val) {
    state.allApplications = val.data.items;
    state.serverItemsLength = val.data.count;
  },
  setSelectedApplication(state, val) {
    state.selectedApplication = val;
  },
  setFilters(state, val) {
    state.filters = val;
  },
  setApplicationTableStatus(state, val) {
    state.applicationTableStatus = val;
  },
  setApplicationRoles(state, val) {
    state.allRoles = val;
  },
  setAllApplicationsDropdown(state, val) {
    state.allApplicationsDropdown = val.data.items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
